import React from 'react';

function Settings() {
  return (
    <div>
      <h1>Settings Page</h1>
      {
      }
    </div>
  );
}

export default Settings;